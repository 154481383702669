import Axios from "axios";

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

const fetchProductsSuccess = products => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products
});

// fetch products
export const fetchProducts = () => {
  return dispatch => {
    Axios.get("http://localhost:3000/api/products/all").then(res=>{
      console.log("from redux ",res.data)
      dispatch(fetchProductsSuccess(res.data));
    }).catch(err=>{
      console.log("err prod action : ",err)
    })
    
  };
};
